import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  computed: {
    ...mapGettersConfig({
      navbarMainItems: configTypes.GET_MEGAMENU_NAVBAR_MAIN_ITEMS,
      navbarItems: configTypes.GET_MEGAMENU_NAVBAR_ITEMS,
    }),
  },

  methods: {
    getNodeUrl (node) {
      if (!node?.urlKey) {
        return '#'
      }

      if (node.type === 'product' || node.type === 'category') {
        return node.urlKey ? this.localePath(node.urlKey) : false
      } else {
        if (String(node.content).startsWith('http')) {
          return node.content ?? ''
        }
        return this.localePath(node.content ?? node.urlKey)
      }
    },
    hasChildren (node) {
      return node?.children?.length > 0
    },
  },
}
