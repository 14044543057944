
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuSheetMobile',
  mixins: [megaMenuMixin],

  props: {
    isNavbarOpen: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      stack: [], // Stos odwiedzonych węzłów rodziców
      currentNode: null,
      isScrollable: false,
    }
  },

  computed: {
    root () {
      return this.navbarMainItems?.[0]
    },

    canGoUp () {
      return this.stack.length > 0
    },

    navbarMainItemsWithoutChildren () {
      // Filtruje węzły bez children z navbarMainItems
      return this.navbarMainItems?.filter(node => !this.hasChildren(node)) || []
    },
  },

  watch: {
    isNavbarOpen (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const scrollableDiv = this.$refs.mobileContent

          if (scrollableDiv) {
            this.isScrollable =
              scrollableDiv.scrollHeight > scrollableDiv.clientHeight
          }
        })
      } else {
        // Reset, wracamy do root
        this.stack = []
        this.currentNode = this.root
      }
    },
  },

  created () {
    this.currentNode = this.root
  },

  methods: {
    handleScroll () {
      const scrollableDiv = this.$refs.mobileContent
      const maxScrollTop = scrollableDiv.scrollHeight - scrollableDiv.clientHeight
      const currentScrollTop = scrollableDiv.scrollTop

      // isScrollable = true, jeśli nie dotarliśmy do samego dołu listy
      this.isScrollable = currentScrollTop < maxScrollTop
    },

    onChildClick (node) {
      if (this.hasChildren(node)) {
        // Jeśli węzeł ma dzieci – wchodzimy w głąb (stack push)
        this.stack.push(this.currentNode)
        this.currentNode = node
      } else {
        // W przeciwnym razie zamykamy menu i przekierowujemy
        this.$emit('toggle-navbar', false)
        this.$router.push(this.getNodeUrl(node))
      }
    },

    goUp () {
      // Cofanie w drzewie
      if (this.stack.length) {
        this.currentNode = this.stack.pop()
      }
    },
  },
}
